import { minBy as _minBy } from 'lodash-es';
import {loadGoogleMaps} from '../plugins/google-maps';
import mapStyles from './_mapStyles';
import { GooglePlaces } from '../plugins/google-places';
import { pushTrackerEvent } from '../_googleTagManager';
import runOnVisible from '../runner-visible';

const mapCenter = {
  lat: 48.12998369465293,
  lng: 11.571394000830706
};

export class FilterableMap {
  constructor($el, markerUrl, markerLocationUrl) {
    this.$el = $el;
    this.markerUrl = markerUrl;
    this.markerLocationUrl = markerLocationUrl;
    this.markers = [];
    this.visible = false;

    /**
     * @type {google.maps.Map|null}
     */
    this.map = null;

    /**
     * @type {google.maps}
     */
    this.googleMaps = null;

    /**
     *
     * @type {GooglePlaces|null}
     */
    this.places = null;

    /**
     * @type {null|google.maps.Marker}
     */
    this.placeMarker = null;

    /**
     * @type {null|google.maps.LatLng}
     */
    this.selectedLocation = null;

    this.suggestionVisibleListener = null;
  }

  /**
   * Adds a marker to the map
   *
   * @param {number} lat
   * @param {number} lng
   * @param {Element} content
   * @param {string} locationName
   */
  addMarker(lat, lng, content, locationName) {
    const marker = new this.googleMaps.Marker({
      position: {
        lat,
        lng
      },
      icon: this.markerUrl,
      map: this.map,
    });

    this.infoWindow = new this.googleMaps.InfoWindow();

    const listener = marker.addListener('click', () => {
      pushTrackerEvent('clickLocationMarker', {
        location: locationName,
      });

      if (content == null) {
        this.infoWindow.close();

        return;
      }

      this.infoWindow.setContent(content);

      this.infoWindow.open({
        anchor: marker,
        map: this.map,
        shouldFocus: false,
      });
    });

    const createdMarker = {
      marker,
      listener,
    };

    this.markers.push(createdMarker);

    return createdMarker;
  }

  /**
   * Removes a marker from the map
   *
   * @param {{ marker: google.maps.Marker, listener: google.maps.MapsEventListener }} createdMarker
   */
  removeMarker(createdMarker) {
    if (this.googleMaps == null) {
      return;
    }

    const { marker, listener } = createdMarker;

    marker.setMap(null);
    this.googleMaps.event.removeListener(listener);
  }

  clearMarkers() {
    for (let i = 0; i < this.markers.length; i++) {
      this.removeMarker(this.markers[i]);
    }

    this.markers.length = 0;
  }

  showMap() {
    return new Promise(async (resolve) => {
      if (this.visible) {
        return resolve();
      }

      runOnVisible(this.$el[0], async () => {
        const google = await loadGoogleMaps();
        this.googleMaps = google.maps;

        this.map = new this.googleMaps.Map(this.$el[0], {
          center: mapCenter,
          zoom: 10,
          mapTypeControl: false,
          styles: mapStyles,
        });

        const idleListener = this.map.addListener('idle', () => {
          resolve();

          this.googleMaps.event.removeListener(idleListener);
        });
      })

      this.visible = true;
    });
  }

  enableAutocomplete(input) {
    this.places = new GooglePlaces(this.googleMaps);
    this.places.startAutocomplete(input, this.map);

    this.places.addPlaceChangedListener(this.onPlaceChanged.bind(this));
    this.places.addPlaceSuggestionToggleListener(this.onSuggestionToggle.bind(this));
  }

  /**
   * @param {google.maps.places.PlaceResult} place
   */
  onPlaceChanged(place) {
    pushTrackerEvent('selectPlaceSuggestion', {
      suggestion: {
        place_id: place.place_id,
        name: place.name,
        address: place.formatted_address,
      },
    });

    this.placeMarker?.setMap(null);

    this.placeMarker = new this.googleMaps.Marker({
      position: place.geometry.location,
      map: this.map,
      animation: this.googleMaps.Animation.DROP,
      icon: this.markerLocationUrl,
    });

    this.selectedLocation = place.geometry.location;

    if (this.focusOnResults() === false) {
      this.map.panTo(place.geometry.location);
      this.map.setZoom(11);
    }
  }

  addSuggestionVisibleListener(callback) {
    this.suggestionVisibleListener = callback;
  }

  onSuggestionToggle(visible) {
    this.suggestionVisibleListener?.(visible);
  }

  hideMap() {
    if (this.visible === false) {
      return;
    }

    this.clearMarkers();

    this.visible = false;
  }

  getDistanceBetween(positionA, positionB) {
    return this.googleMaps.geometry.spherical.computeDistanceBetween(positionA, positionB);
  }

  getClosestMarker() {
    return _minBy(this.markers, ({ marker }) => {
      return this.getDistanceBetween(marker.getPosition(), this.selectedLocation);
    });
  }

  getCloseMarkers(maxDistance) {
    return this.markers.filter(({ marker }) => {
      return this.getDistanceBetween(marker.getPosition(), this.selectedLocation) < maxDistance * 1000;
    });
  }

  focusOnMarkers() {
    return this.focusPositions(
      this.markers.map(({ marker }) => {
        return marker.getPosition();
      }),
    );
  }

  focusOnResults() {
    if (this.focusOnCloseMarkers()) {
      return true;
    }

    return this.focusOnClosestMarker();
  }

  focusOnClosestMarker() {
    const { marker: closestMarker } = this.getClosestMarker();
    const markerDistance = this.getDistanceBetween(closestMarker.getPosition(), this.selectedLocation);
    if (markerDistance > 50) {
      return false;
    }

    return this.focusPositions([
      this.selectedLocation,
      closestMarker.getPosition(),
    ]);
  }

  focusOnCloseMarkers() {
    const maxDistance = 25;
    const closeMarkers = this.getCloseMarkers(maxDistance);
    if (closeMarkers.length <= 0) {
      return false;
    }

    return this.focusPositions([
      this.selectedLocation,
      ...closeMarkers.map(({ marker }) => marker.getPosition()),
    ]);
  }

  focusPositions(positions) {
    if (positions.length === 0) {
      return false;
    }

    if (positions.length === 1) {
      this.map.panTo(positions[0]);
      this.map.setZoom(13);

      return true;
    }

    const bounds = new this.googleMaps.LatLngBounds();
    for (const position of positions) {
      bounds.extend(position);
    }

    this.map.fitBounds(bounds);

    return true;
  }

}
