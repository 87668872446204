import $ from 'jquery';
import Headroom from 'headroom.js';
import Collapse from 'bootstrap/js/dist/collapse';

const baseClass = 'page-header';

// -------------------------------------------------------------------------------------------------
// Classes
// -------------------------------------------------------------------------------------------------

class PageHeader {
  constructor(el) {
    this.el = el;
    this.headroom = false;
    this._firstMenuId = "";
    this._activeMenuTabId = null;
    this.initHeadroom();
    this.$btnSearch = $(el).find(`.${baseClass}__btn-search`);
    this.collapseEl = this.el.getElementsByClassName('collapse')[0];

    this.$btnSearch.on('click', () => {
      document.dispatchEvent(new CustomEvent('theme_search-autofocus'));
    });

    if (this.collapseEl) {
      this.collapsible = Collapse.getOrCreateInstance(this.collapseEl, {
        toggle: false
      });

      this.collapseEl.addEventListener('click', (event) => {
        const {target} = event;
        const parent = target.closest('.nav-link');

        if (target.classList.contains('nav-link') === false && parent == null) {
          return;
        }

        this.collapsible.hide();
      });
    }


    this.buildSecondaryMenu();
  }

  initHeadroom() {
    // @see https://wicky.nillia.ms/headroom.js/
    this.headroom = new Headroom(this.el, {
      // Unfortunately 'offset' cannot be updated dynamically on window-resize
      offset: 122, // Keep this in sync with CCS var `--theme_header-height`
    });

    this.headroom.init();

    window.addEventListener('theme_headroom_pin', () => {
      this.headroom.pin();
    });

    window.addEventListener('theme_headroom_unpin', () => {
      this.headroom.unpin();
    });
  }

  buildSecondaryMenu() {
    $(this.el).find('.header-secondary__ul').clone().appendTo($('.navbar-collapse'));

    const menuButtons = $(this.el).find('.navbar__menu button');
    const mainButton = menuButtons.first();
    if (mainButton.length > 0) {
      this._firstMenuId = mainButton.attr('aria-controls');

      this.activeMenuTabId = this._firstMenuId;
    }

    if ($('.content-group').length > 0) {
      const subMenuButtons = menuButtons.last();
      if (menuButtons.length > 1 && subMenuButtons.length > 0) {
        this.activeMenuTabId = subMenuButtons.attr('aria-controls');
      }
    }

    $(this.el).on('click', `.navbar__menu button`, (e) => {
      this.activeMenuTabId = e.currentTarget.getAttribute('aria-controls');
    });
  }

  get activeMenuTabId() {
    return this._activeMenuTabId;
  }

  set activeMenuTabId(value) {
    $(this.el).find('.navbar__menu button').removeClass('active');
    $(this.el).find(`[aria-controls="${CSS.escape(value)}"]`).addClass('active');

    $(this.el).find('.navbar__ul').removeClass('is-visible');
    $(`#${value}`).addClass('is-visible');

    if (value === this._firstMenuId) {
      $(this.el).find('.navbar-collapse .header-secondary__ul').addClass('is-visible');
    } else {
      $(this.el).find('.navbar-collapse .header-secondary__ul').removeClass('is-visible');
    }

    this._activeMenuTabId = value;
  }
}

// -------------------------------------------------------------------------------------------------
// Methods
// -------------------------------------------------------------------------------------------------

function init() {
  $(`.${baseClass}`).each((i, el) => new PageHeader(el));
}

// -------------------------------------------------------------------------------------------------
// Export
// -------------------------------------------------------------------------------------------------

export default {
  init,
};
