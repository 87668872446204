import PlacesAutocompleteWatcher from './google-places-watcher';

export class GooglePlaces {
  constructor(googleMaps) {
    /**
     * @type {google.maps.places.Autocomplete|null}
     */
    this.autocomplete = null;
    this.googleMaps = googleMaps;
    this.initialized = true;

    /**
     * @type {PlacesAutocompleteWatcher}
     */
    this.placesInputWatcher = new PlacesAutocompleteWatcher();

    this.placeListener = null;
  }

  /**
   * @param {Element} inputElement
   * @param {google.maps.Map} map
   */
  startAutocomplete(inputElement, map) {
    GooglePlaces.enableEnterKey(inputElement);

    this.autocomplete = new this.googleMaps.places.Autocomplete(inputElement);
    this.autocomplete.bindTo('bounds', map);

    this.autocomplete.addListener('place_changed', () => {
      this.placeListener?.(this.autocomplete.getPlace());
    });

    this.placesInputWatcher.start();
  }

  /**
   * @callback {(google.maps.places.PlaceResult) => void} callback
   */
  addPlaceChangedListener(callback) {
    this.placeListener = callback;
  }

  /**
   * @callback {(boolean) => void} callback
   */
  addPlaceSuggestionToggleListener(callback) {
    this.placesInputWatcher.addChangedListener(callback);
  }

  /**
   * Attaches an event listener that chooses the first result in google places on enter
   *
   * @param {Element} input
   */
  static enableEnterKey(input) {
    /* Store original event listener */
    const inputField = input;
    const { addEventListener } = input;

    const addEventListenerWrapper = (type, listener) => {
      let newListener = listener;
      if (type === 'keydown') {
        /* Store existing listener function */
        newListener = (event) => {
          /* Simulate a 'down arrow' keypress if no address has been selected */
          const suggestionSelected = document.getElementsByClassName('pac-item-selected').length;

          if (event.key === 'Enter' && !suggestionSelected) {
            const e = new KeyboardEvent('keydown', {
              key: 'ArrowDown',
              code: 'ArrowDown',
              keyCode: 40,
            });

            listener.apply(input, [ e ]);
          }
          listener.apply(input, [ event ]);
        };
      }
      addEventListener.apply(input, [ type, newListener ]);
    };

    inputField.addEventListener = addEventListenerWrapper;
  }
}

