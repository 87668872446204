import { Loader as MapLoader } from '@googlemaps/js-api-loader';

let loadedMap = null;
let mapLoader = null;

export function loadGoogleMaps() {
  console.debug('New Google Map requested');

  if (mapLoader != null) {
    console.debug('Already running loading promise provided');

    return mapLoader;
  }

  console.debug('Create new Google Map loader');

  mapLoader = new Promise(async (resolve) => {
    console.debug('Setup Google Map Loader');

    const { mapsApiKey: apiKey } = window;

    const mapLoader = new MapLoader({
      apiKey: apiKey ?? process.env.MIX_GOOGLE_MAPS_API_KEY,
      language: 'de',
      region: 'DE',
      version: 'quarterly',
      libraries: [
        'geometry',
        'places',
      ],
    });

    console.debug('Load Google Map');
    loadedMap = await mapLoader.load();

    console.debug('Google Map Load finished');

    resolve(loadedMap);
  });

  return mapLoader;
}
