// -------------------------------------------------------------------------------------------------
// Import
// -------------------------------------------------------------------------------------------------

import $ from 'jquery';
import utilLib from '../_util';

// -------------------------------------------------------------------------------------------------
// Variables
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// Classes
// -------------------------------------------------------------------------------------------------

class LazyBg {
  constructor(el) {
    this.waypoint = null;
    this.imgSrc = el.dataset.lazyBg;
    this.el = el;
    this.initWaypoint();
  }

  initWaypoint() {
    this.waypoint = new IntersectionObserver((o) => {
      if (o[0].isIntersecting) {
        utilLib.loadImageThen(this.imgSrc, () => {
          this.el.classList.add('lazy-bg-loaded');
          this.el.style.backgroundImage = `url('${this.imgSrc}')`;
          this.waypoint.disconnect();
        });
      }
    }, {
      rootMargin: '125% 0px',
      threshold: 0,
    });
    this.waypoint.observe(this.el);
  }
}

// -------------------------------------------------------------------------------------------------
// Methods
// -------------------------------------------------------------------------------------------------

function init() {
  $('[data-lazy-bg]').each((i, el) => new LazyBg(el));
}

// -------------------------------------------------------------------------------------------------
// Export
// -------------------------------------------------------------------------------------------------

export default {
  init,
};
