import Modal from 'bootstrap/js/src/modal';
import {enableBlurBackdrop} from '../plugins/bootstrap';
import {dispatchLayoutChange} from '../_util';

export class BigModal {
  /**
   * @type {(() => void)[]}
   * @private
   */
  _modalHideListeners = [];

  /**
   * @type {boolean}
   * @private
   */
  _open = false;

  /**
   * @type {Modal|null}
   * @private
   */
  _overlayModal = null;

  /**
   * @type {HTMLElement|null}
   * @private
   */
  _overlay = null;

  /**
   * @type {HTMLElement|null}
   * @private
   */
  _closeButton = null;

  /**
   * @type {BigModal[]}
   * @private
   */
  static _openModals = [];

  show(template, notifyLayoutChange = true) {
    if (this._open) {
      return;
    }

    BigModal._openModals.push(this);

    ({
      /** @type {HTMLElement} */ overlay: this._overlay,
      /** @type {HTMLElement} */ closeButton: this._closeButton
    } = this._createBigOverlay(template));

    document.body.append(this._overlay);

    this._overlayModal = /** @type {Modal} */ Modal.getOrCreateInstance(this._overlay, {
      backdrop: 'fix', // Any value other than true or 'static' would hide the modal on click or show ugly animation.
    });
    this._overlayModal.show();

    enableBlurBackdrop();
    this.attachCloseEvents();

    this._open = true;

    if (notifyLayoutChange) {
      dispatchLayoutChange();
    }
  }

  hide() {
    if (this._open === false) {
      return;
    }

    this._overlayModal.hide();
  }

  dispose() {
    this._modalHideListeners.length = 0;
  }

  /**
   * Adds this listener which will be called as soon as this modal closes.
   *
   * @param {() => void} callback
   */
  onModalHide(callback) {
    this._modalHideListeners.push(callback);
  }

  _callOnModalHide() {
    for (const modalHideListener of [...this._modalHideListeners]) {
      modalHideListener();
    }
  }

  /**
   * Attaches special events to currently open tile to close it.
   */
  attachCloseEvents() {
    this._closeButton?.addEventListener('click', this.handleCloseClick.bind(this), {
      passive: true,
    });

    this._overlay?.addEventListener('hidden.bs.modal', this.handleModalClose.bind(this), {
      passive: true,
    });
  }

  /**
   * Attaches special events to currently open tile to close it.
   */
  detachCloseEvents() {
    this._closeButton?.removeEventListener('click', this.handleCloseClick.bind(this));
    this._overlay?.removeEventListener('hidden.bs.modal', this.handleModalClose.bind(this));
  }

  /**
   * Handles a click on a previously created close button.
   */
  handleCloseClick() {
    this._overlayModal?.hide();
  }

  /**
   * Handles closing a modal after it has been closed completely. Necessary, because otherwise ESC
   * is not handled correctly.
   */
  handleModalClose() {
    this._callOnModalHide();
    this.detachCloseEvents();

    this._overlayModal.dispose();
    this._overlay.remove();

    this._overlay = null;
    this._overlayModal = null;

    BigModal._openModals.splice(BigModal._openModals.indexOf(this));
    this._open = false;
  }

  /**
   * Builds the markup for the big overlay / modal to be drawn below the tile.
   *
   * @param {HTMLElement} innerElement
   * @returns {{ overlay: HTMLElement, closeButton: HTMLElement }}
   */
  _createBigOverlay(innerElement) {
    const wrapper = document.createElement('div');
    wrapper.classList.add(...['modal', 'modal--blur']);
    wrapper.tabIndex = -1;

    const closeButton = document.createElement('div');
    closeButton.classList.add(...['modal-close-button', 'modal-close-button--rotate']);
    closeButton.innerHTML = `
        <button type="button">
            <i class="fa fa-circle-plus"></i>
        </button>
    `;

    const modalDialog = document.createElement('div');
    modalDialog.classList.add(...['modal-dialog', 'modal-fullscreen', 'modal-dialog--custom']);
    modalDialog.append(innerElement);

    wrapper.append(closeButton);
    wrapper.append(modalDialog);

    return {
      overlay: wrapper,
      closeButton: closeButton,
    };
  }

  get isOpen() {
    return this._open;
  }

  static hideAll() {
    for (const openModal of BigModal._openModals) {
      openModal.hide();
    }
  }
}
