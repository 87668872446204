import { tryExecution } from './_util';

let pageLoaded = false;

let callbacks = [];

export function runOnPageload(callback) {
  if (pageLoaded) {
    callback();

    return;
  }

  callbacks.push(callback);
}

function runCallbacks() {
  pageLoaded = true;

  for (const callback of callbacks) {
    tryExecution(callback);
  }

  window.removeEventListener('load', runCallbacks);
}

export function initializePageloadRunner() {
  window.addEventListener('load', runCallbacks, {
    passive: true,
  });
}
