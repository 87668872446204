// -------------------------------------------------------------------------------------------------
// Import external dependencies

import $ from 'jquery';

// -------------------------------------------------------------------------------------------------
// Import local dependencies

// import Foo from './app/_foo';

// -------------------------------------------------------------------------------------------------
// Import theme components

import utilLib from './_util';
import themeCommon from './theme/_common';
import themeLazyBg from './theme/_lazyBg';
// import themePageSearchModal from './theme/_pageSearchModal';
import themePageHeader from './theme/_pageHeader';
import themeContentHeader from './theme/_contentHeader';
// import themeContentWithSidebar from './theme/_contentWithSidebar';
// import themeBigLeaf from './theme/_bigLeaf';
import themeJumbo from './theme/_jumbo';
// import themeSectorsSlider from './theme/_sectorsSlider';
// import themeTopicsCatalog from './theme/_topicsCatalog';
// import themeLocationsCatalog from './theme/_locationsCatalog';
// import themeLocationTour from './theme/_locationTour';
// import themeLocationMap from './theme/_locationMap';
// import themeStaffGrid from './theme/_staffGrid';
// import themeShareButton from './theme/_shareButton';
import themeSliderWithFilter from './theme/_sliderWithFilter';
// import themeFormSelect from './theme/_formSelect';
// import themeContactForm from './theme/_contactForm';
import themeSliderCarousel from './theme/_sliderCarousel'
// import themeFloatingContactIcon from './theme/_floatingContactIcon';
// import themeScrollIndicator from './theme/_scrollIndicator';
// import themeHrlistSplit from './theme/_hrlist-split';
// import themeNewsListView from './theme/_newsListView';
// import themeVirtuelleTour from './theme/_virtuelle-tour';
import themeLazyImages from './theme/_lazyImages';
// import themeContactIcon from './theme/_contactIcon';
import themeImageGalleryExtended from './theme/_image-gallery-extended';
import initTextSwitch from './theme/_textSwitch';

// import initContentGroup from './initializers/_contentGroupInit';

// import counterStatistic from './theme/_counterStatistic'
import { initializePageloadRunner } from './runner-pageload';
import initializeTiles from './theme/_tiles';

// -------------------------------------------------------------------------------------------------
// Load files

window.ResizeSensor = require('css-element-queries/src/ResizeSensor');
window.jQuery = $;

__webpack_public_path__ = utilLib.getAssetPath(document.currentScript.src);

// -------------------------------------------------------------------------------------------------
// Functions

// To allow every module to initialize, even when single modules fail.
function initializeModuleSafely(initializer) {
  if (initializer == null || typeof initializer !== "function") {
    console.warn("Unable to start module, function expected");

    return;
  }

  try {
    initializer();
  } catch (e) {
    console.error("An unexpected error occured during module initialization", e);
  }
}

function sendBeacons() {
  // Use in JS to avoid blocking source
  navigator.sendBeacon('https://hello.myfonts.net/count/3c7704');
}

// -------------------------------------------------------------------------------------------------
// Init app

initializePageloadRunner();

window.addEventListener('DOMContentLoaded', () => {
  const initializers = [
    themeLazyBg.init,
    themeLazyImages.init,
    themePageHeader.init,
    // themeContactIcon.init,
    // themeHrlistSplit.init,
    initTextSwitch,
    themeContentHeader.init,
    // initContentGroup,
    // themeContentWithSidebar.init,
    // themeBigLeaf.init,
    // themeSectorsSlider.init,
    // themeTopicsCatalog.init,
    // themeLocationsCatalog.init,
    themeSliderWithFilter.init,
    themeSliderCarousel.init,
  ];

  for (const initializer of initializers) {
    initializeModuleSafely(initializer);
  }
});

window.addEventListener('load', () => {
  sendBeacons();

  const initializers = [
    // themePageSearchModal.init,
    themeJumbo.init,
    // themeLocationTour.init,
    // themeLocationMap.init,
    // themeStaffGrid.init,
    // themeShareButton.init,
    // themeFormSelect.init,
    // themeContactForm.init,
    // themeScrollIndicator.init,
    // themeFloatingContactIcon.init,
    // themeNewsListView.init,
    themeImageGalleryExtended.init,
    // counterStatistic,
    // themeVirtuelleTour.init,
    initializeTiles,
  ];

  for (const initializer of initializers) {
    initializeModuleSafely(initializer);
  }

  $(document).on('click', 'a[href*="#"]', (e) => {
    e.preventDefault();

    const consentmanager = document.getElementById('cmpbox');

    if (consentmanager && window.getComputedStyle(consentmanager).display !== 'none') {
      return;
    }

    if (!themeCommon.smoothScrollTo(e.currentTarget.hash, 750)) {
      window.location.href = e.currentTarget.href;
    }
  });
});


