import {dispatchLayoutChange, tryExecution} from '../_util';
import {BigModal} from './_bigModal';

const baseClass = 'tile';
const EVENT_TILE_OPEN = 'tile-overlay-open';

const OVERLAY_TYPE_SMALL = 'small';
const OVERLAY_TYPE_BIG = 'big';

class Tile {
  /**
   * @private
   * @type {HTMLElement}
   */
  _el;

  /**
   * @type {"big"|"small"}
   * @private
   */
  _overlayType = OVERLAY_TYPE_SMALL;

  /**
   * @private
   * @type {boolean}
   */
  _isOpen = false;

  /**
   * @private
   * @type {HTMLElement|null}
   */
  _toggleButton;

  /**
   * @private
   * @type {HTMLTemplateElement|null}
   */
  _overlayTemplate;

  /**
   * @private
   * @type {HTMLElement|null}
   */
  _overlay = null;

  /**
   *
   * @type {BigModal|null}
   * @private
   */
  _bigModal = null;

  /**
   * @param {HTMLElement} element
   */
  constructor(element) {
    this._el = element;

    this._toggleButton = this._el.getElementsByClassName(`${baseClass}__toggle`)[0] ?? null;
    this._overlayTemplate = this._el.getElementsByClassName(`${baseClass}__overlay-template`)[0] ?? null;

    if (this._el.classList.contains(`${baseClass}--big-overlay`)) {
      this._overlayType = OVERLAY_TYPE_BIG;
    }

    if (this._toggleButton != null) {
      this._el.classList.add(`${baseClass}--clickable`);
    }

    this.attachEvents();
  }

  /**
   * Attaches general events to tile.
   */
  attachEvents() {
    if (this._toggleButton != null) {
      this._toggleButton.addEventListener('click', (event) => {
        this.isOpen = this.isOpen === false

        event.stopPropagation();
      });

      this._el.addEventListener('click', () => {
        if (this.isOpen) {
          return;
        }

        this.isOpen = true;
      });
    }

    document.addEventListener(EVENT_TILE_OPEN, () => this.isOpen = false);
  }
  /**
   * Handles closing a modal after it has been closed completely. Necessary, because otherwise ESC
   * is not handled correctly.
   */
  handleModalClose() {
    this.isOpen = false;
  }

  /**
   * Get the content that should be visible inside the overlay.
   *
   * @returns {HTMLElement|null}
   */
  getOverlayTemplate() {
    if (this._overlayTemplate == null) {
      return null;
    }

    return /** @type {HTMLElement} **/ this._overlayTemplate.content.cloneNode(true);
  }

  /**
   * Shows the overlay for the current
   */
  showOverlay() {
    if (this._overlay != null) {
      return;
    }

    const template = this.getOverlayTemplate();
    if (template == null) {
      console.warn('Could not find overlay template. Element: ', this._el);

      return;
    }

    document.dispatchEvent(new CustomEvent(EVENT_TILE_OPEN));

    switch (this._overlayType) {
      case OVERLAY_TYPE_BIG:
        this.showBigOverlay(template);

        break;

      default:
        this.showSmallOverlay(template);

        break;
    }

    this._el.classList.add(`${baseClass}--open`);

    dispatchLayoutChange();
  }

  /**
   * Creates and shows the small overlay. The small overlay is just visible inside the tile.
   *
   * @param {HTMLElement} template
   */
  showSmallOverlay(template) {
    this._overlay = this.createSmallOverlay(template);
    this._el.appendChild(this._overlay);

    this.isBodyVisible = false;

    this._el.classList.add(`${baseClass}--open-inline`);

    // Timeout to allow browser to create element first and then apply animation.
    setTimeout(() => {
      this._el.classList.add(`${baseClass}--overlay-open`);
    }, 100);
  }

  /**
   * Creates and shows the big overlay. This overlay is inside a bootstrap modal.
   *
   * @param {HTMLElement} template
   */
  showBigOverlay(template) {
    this._bigModal = new BigModal();
    this._bigModal.show(template, false);

    this._bigModal.onModalHide(this.handleModalClose.bind(this));
  }

  /**
   * Hides any currently open overlay.
   */
  hideOverlay() {
    this._el.classList.remove(...[
      `${baseClass}--overlay-open`,
      `${baseClass}--open`
    ]);

    switch (this._overlayType) {
      case OVERLAY_TYPE_BIG:
        return this.hideBigOverlay();
      default:
        return this.hideSmallOverlay();
    }
  }

  /**
   * Hides the open big overlay and clears all references.
   */
  hideBigOverlay() {
    BigModal.hideAll();

    if (this._bigModal != null) {
      this._bigModal.dispose();
      this._bigModal = null;
    }
  }

  /**
   * Hides the small overlay and removes all references.
   */
  hideSmallOverlay() {
    this.isBodyVisible = true;

    if (this._overlay == null) {
      return;
    }

    this._el.classList.remove(`${baseClass}--open-inline`);

    this._overlay.remove();
    this._overlay = null;
  }

  /**
   * Builds the markup for the small overlay to be drawn inside the tile.
   *
   * @param {HTMLElement} innerElement
   * @returns {HTMLElement}
   */
  createSmallOverlay(innerElement) {
    const wrapper = document.createElement('div');
    wrapper.classList.add(`${baseClass}__overlay`);
    wrapper.appendChild(innerElement);

    return wrapper;
  }

  /**
   * @return {boolean}
   */
  get isOpen() {
    return this._isOpen;
  }

  /**
   * @param {boolean} value
   */
  set isOpen(value) {
    if (this._isOpen === value) {
      return;
    }

    if (value === false) {
      this.hideOverlay();
      this._isOpen = false;

      return;
    }

    this.showOverlay();
    this._isOpen = true;
  }

  /**
   * @param {Boolean} value
   */
  set isBodyVisible(value) {
    const bodies = this._el.getElementsByClassName(`${baseClass}__body`);

    for (const body of bodies) {
      body.setAttribute('aria-hidden', (value === false).toString());
    }
  }
}

export default function initializeTiles() {
  const tiles = document.getElementsByClassName('tile');

  for (const tile of tiles) {
    tryExecution(() => new Tile(tile));
  }
}
