import {
  forEach as _forEach,
  get as _get,
  isFunction as _isFunction,
  trimStart as _trimStart,
} from 'lodash-es';
import $ from 'jquery';
import {
  parse as queryParse,
  stringify as queryStringify,
} from 'query-string';

let query = queryParse(window.location.search);
export const LAYOUT_CHANGE_EVENT = 'kirinus-layout-change';

function getElementOffset(el) {
  const winScrollPos = getWindowScrollPos();
  const elRect = el.getBoundingClientRect();
  return {
    top: (elRect.top + winScrollPos.top),
    left: (elRect.left + winScrollPos.left),
  };
}

function getFormData(formEl) {
  const dataSerialized = $(formEl).serializeArray();
  const data = new FormData();
  _forEach(dataSerialized, (field) => {
    data.append(field.name, field.value);
  });
  return data;
}

function getHash() {
  return _trimStart(window.location.hash, '#');
}

function getQueryVar(key) {
  return _get(query, key);
}

function deleteQueryVar(key) {
  if (Object.prototype.hasOwnProperty.call(query, key) === false) {
    return;
  }

  delete query[key];

  const newUrl = buildUrlFromQuery();
  window.history.replaceState(null, '', newUrl);
}

function setQueryVar(key, value) {
  query[key] = value;

  const newUrl = buildUrlFromQuery();
  window.history.replaceState(null, '', newUrl);
}

function getQuerVariables() {
  return Object.keys(query);
}

function buildUrlFromQuery() {
  return `${window.location.protocol
  }//${
    window.location.host
  }${window.location.pathname
  }?${
    queryStringify(query)
  }${window.location.hash}`;
}

function getWindowScrollPos() {
  return {
    left: (window.pageXOffset || document.documentElement.scrollLeft),
    top: (window.pageYOffset || document.documentElement.scrollTop),
  };
}

function loadImageThen(src, cb) {
  const image = new Image();
  if (_isFunction(cb)) image.onload = cb;
  image.src = src;
}

function scrollSmoothly(target) {
  $('html,body')
    .stop()
    .animate({
      scrollTop: $(target).offset().top
    }, 'slow');
}

function convertCssDurationToMilliseconds(time) {
  if (/ms$/.test(time)) {
    return parseFloat(time);
  }

  return parseFloat(time) * 1000;
}

function getChildElementAlignment(wrapper) {
  const wrapperContentJustify = window.getComputedStyle(wrapper).getPropertyValue('justify-content');

  switch (wrapperContentJustify) {
    case "flex-end":
      return "right";

    case "normal":
    case "flex-start":
      return "left";

    default:
      return null;
  }
}

export function tryExecution(action)
{
  if (action == null || typeof action !== "function") {
    console.warn("Unable to execute action, function expected. Action: ", action);

    return;
  }

  try {
    return action();
  } catch (e) {
    console.error("An unexpected error occured during action execution", e);
  }
}

function flipObject(value) {
  if (value == null) {
    throw new Error(`Null value passed to ${flipObject.name}, object needed.`);
  }

  return Object.entries(value).reduce((previousValue, [ key, values ]) => {
    if (Array.isArray(values)) {
      for (const value of values) {
        if (Object.prototype.hasOwnProperty.call(previousValue, value) && Array.isArray(previousValue[value])) {
          previousValue[value].push(key);
        } else {
          previousValue[value] = [ key ];
        }
      }
    } else {
      previousValue[values] = [ key ];
    }

    return previousValue;
  }, {});
}

export function dispatchLayoutChange() {
  document.dispatchEvent(new CustomEvent(LAYOUT_CHANGE_EVENT));
}

export function isUrlEqual(relativeUrl, absoluteUrl) {
  const cleanRelativeUrl = relativeUrl?.trim();
  const cleanAbsoluteUrl = absoluteUrl?.trim();

  if (cleanRelativeUrl == null || cleanAbsoluteUrl == null || cleanRelativeUrl === '' || cleanAbsoluteUrl === '') {
    return false;
  }

  if (relativeUrl === absoluteUrl) {
    return true;
  }

  const currentUrl = new URL(absoluteUrl);
  const convertedRelativeUrl = new URL(relativeUrl, currentUrl.origin);

  return convertedRelativeUrl.href === currentUrl.href;
}

/**
 * @param {string|number} pixels
 * @param {(boolean) => void} callback
 * @returns {MediaQueryList}
 */
export function watchScreenWidth(pixels, callback) {
  const matcher = window.matchMedia(`(max-width: ${pixels}px)`);

  if (matcher.matches) {
    callback(true);
  }

  matcher.addEventListener('change', (event) => {
    callback(event.matches);
  });

  return matcher;
}

function getAssetPath(scriptUrl) {
  const url = new URL(scriptUrl);
  const path = url.pathname;

  return path.substring(0, path.lastIndexOf('/') + 1);
}

export default {
  getElementOffset,
  getFormData,
  getHash,
  getQueryVar,
  setQueryVar,
  deleteQueryVar,
  getQuerVariables,
  getWindowScrollPos,
  loadImageThen,
  scrollSmoothly,
  convertCssDurationToMilliseconds,
  getChildElementAlignment,
  tryExecution,
  flipObject,
  getAssetPath,
};
