import {BigModal} from './_bigModal';
import {tryExecution} from '../_util';

const baseClass = 'image-gallery-extended';

class ImageGalleryExtended {
  /**
   * @type {HTMLElement}
   * @private
   */
  _el;

  /**
   * @type {HTMLButtonElement|null}
   * @private
   */
  _toggleEl = null;

  /**
   * @type {HTMLElement[]}
   * @private
   */
  _imagesEl = [];

  /**
   * @type {BigModal|null}
   * @private
   */
  _bigModal = null;

  /**
   * @type {DocumentFragment|null}
   * @private
   */
  _modalContent = null;

  /**
   * @type {object|null}
   * @private
   */
  _modalSlider = null;

  constructor(element) {
    this._el = element;
    this._toggleEl = element.getElementsByClassName(`${baseClass}__toggle`)[0] || null;
    this._imagesEl = /** @type {HTMLElement[]} */ this._el.querySelectorAll(`.${baseClass}__inner .${baseClass}__image`);

    if ([...this._imagesEl].some(image => image.tag === "a") || this._toggleEl != null) {
      this.initializeBigModal();
      this.attachEvents();
    }
  }

  initializeBigModal() {
    this._bigModal = new BigModal();
  }

  attachEvents() {
    if (this._toggleEl != null) {
      this._toggleEl.addEventListener('click', this.handleFullscreenClick.bind(this));
    }

    for (const [index, image] of Object.entries(this._imagesEl)) {
      image.addEventListener('click', async (event) => {
        event.preventDefault();
        event.stopImmediatePropagation();

        await this.handleImageClick(Number.parseInt(index));
      });
    }

    if (this._bigModal != null) {
      this._bigModal.onModalHide(this.handleModalClose.bind(this));
    }
  }

  async handleFullscreenClick() {
    if (this._bigModal == null) {
      console.warn('Unable to access initialized bigmodal. Ignoring click');

      return;
    }

    await this.buildAndShowOverlay();
  }

  async handleImageClick(imageIndex) {
    if (this._bigModal == null) {
      console.warn('Unable to access initialized bigmodal. Ignoring click');

      return;
    }

    const slider = await this.buildAndShowOverlay();
    slider.slideTo(imageIndex, 0);
  }

  /**
   * @returns {Promise<object>}
   */
  async buildAndShowOverlay() {
    let sliderEl;
    [ this._modalContent, sliderEl ] = this.buildGalleryTemplate();

    this._bigModal.show(this._modalContent);
    this._modalSlider = await this.initializeSlider(sliderEl);

    return this._modalSlider;
  }

  handleModalClose() {
    if (this._modalSlider == null) {
      console.warn('Expected slider in modal to be initialized. Skipped destroy')

      return;
    }

    this._modalSlider.destroy(true, true);
    this._modalSlider = null;
  }

  /**
   * @type {[DocumentFragment, HTMLElement]}
   */
  buildGalleryTemplate() {
    const fragment = document.createDocumentFragment();

    const slider = document.createElement('div');
    slider.classList.add('swiper-container', 'image-gallery-extended__overlay');

    const sliderPagination = document.createElement('div');
    sliderPagination.classList.add(`swiper-pagination`);
    const sliderWrapper = document.createElement('div');
    sliderWrapper.classList.add('swiper-wrapper');
    const sliderButtonPrevious = document.createElement('div');
    sliderButtonPrevious.classList.add('swiper-button-prev');
    const sliderButtonNext = document.createElement('div');
    sliderButtonNext.classList.add('swiper-button-next');

    slider.append(sliderWrapper);
    slider.append(sliderPagination);
    slider.append(sliderButtonPrevious);
    slider.append(sliderButtonNext);

    for (let image of this._imagesEl) {
      if (image.tagName.toLowerCase() === 'a') {
        image = image.children[0];
      }

      const slideElement = document.createElement('div');
      slideElement.classList.add('swiper-slide');
      slideElement.append(image.cloneNode(true));

      sliderWrapper.append(slideElement);
    }

    fragment.append(slider);

    return [ fragment, slider ];
  }

  /**
   * @param {HTMLElement} sliderEl
   * @return {Promise<object>}
   */
  initializeSlider(sliderEl) {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          default: Swiper,
          Pagination: SwiperPagination,
          Navigation: SwiperNavigation,
        } = await import('swiper/core');

        Swiper.use([
          SwiperPagination,
          SwiperNavigation,
        ]);

        new Swiper(sliderEl, {
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          },

          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },

          on: {
            afterInit: (slider) => resolve(slider)
          }
        });
      } catch (e) {
        reject(e);
      }
    });
  }
}

function init() {
  const galleries = document.getElementsByClassName(baseClass);

  for (const gallery of galleries) {
    tryExecution(() => new ImageGalleryExtended(gallery));
  }
}

export default {
  init,
};
