 // -------------------------------------------------------------------------------------------------
// Import
// -------------------------------------------------------------------------------------------------
import { isUrlEqual, LAYOUT_CHANGE_EVENT } from '../_util';

// -------------------------------------------------------------------------------------------------
// Variables
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// Methods
// -------------------------------------------------------------------------------------------------

function initializeBrowserLevelLazyLoading() {
  for (const lazyLoadingImage of document.querySelectorAll('[loading="lazy"]')) {

    if (Object.prototype.hasOwnProperty.call(lazyLoadingImage.dataset, 'src') &&
      lazyLoadingImage.dataset.src != null &&
      isUrlEqual(lazyLoadingImage.dataset.src, lazyLoadingImage.src) === false)
    {
      lazyLoadingImage.src = lazyLoadingImage.dataset.src;
    }

    if (Object.prototype.hasOwnProperty.call(lazyLoadingImage.dataset, 'srcset') &&
      lazyLoadingImage.dataset.srcset != null &&
      lazyLoadingImage.dataset.srcset !== lazyLoadingImage.srcset)
    {
      lazyLoadingImage.srcset = lazyLoadingImage.dataset.srcset;
    }

    if (Object.prototype.hasOwnProperty.call(lazyLoadingImage.dataset, 'sizes') &&
      lazyLoadingImage.dataset.sizes != null &&
      lazyLoadingImage.dataset.sizes !== lazyLoadingImage.sizes)
    {
      lazyLoadingImage.sizes = lazyLoadingImage.dataset.sizes;
    }

  }
}

async function initializeLazyloadPolyfill() {
  const { default: lazySizes } = await import('lazysizes');

  Object.assign(lazySizes.cfg, {
    init: false,
  });

  lazySizes.init();
}

function init() {
  if ('loading' in HTMLImageElement.prototype) {
    initializeBrowserLevelLazyLoading();

    document.addEventListener(LAYOUT_CHANGE_EVENT, () => {
      initializeBrowserLevelLazyLoading();
    }, {
      passive: true,
    })
  } else {
    initializeLazyloadPolyfill();
  }
}

// -------------------------------------------------------------------------------------------------
// Export
// -------------------------------------------------------------------------------------------------

export default {
  init,
};
