const { dataLayer } = window;

export function pushTrackerEvent(event, data) {
  const eventName = `kirinus.${event}`;

  const payload = {
    'event': eventName,
    ...data,
  };


  if (dataLayer == null || typeof dataLayer.push !== 'function') {
    console.debug('Event pushed to datalayer: ', payload);

    return;
  }

  dataLayer.push(payload);
}
