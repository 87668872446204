import { LAYOUT_CHANGE_EVENT } from '../_util';

const baseClass = 'text-switch';

const textSwitches = new Map();

class TextSwitch {
  /**
   *
   * @param {HTMLElement} el
   */
  constructor(el) {
    this.el = el;
    this.checked = null;

    this.input = this.el.getElementsByClassName(`${baseClass}__input`)[0];
    this.button = this.el.getElementsByClassName(`${baseClass}__button`)[0];

    this.onButton = this.el.getElementsByClassName(`${baseClass}__on`)[0];
    this.offButton = this.el.getElementsByClassName(`${baseClass}__off`)[0];

    this.button.addEventListener('click', this.onClickButton.bind(this));

    this.initState();
  }

  initState() {
    this.isOn = this.input.checked;
  }

  onClickButton() {
    this.isOn = this.isOn === false;
  }

  updateButtonWidth(value) {
    const targetButton = value ? this.onButton : this.offButton;

    this.el.style.setProperty('--text-switch--selector-width', `${targetButton.clientWidth}px`);
  }

  get isOn() {
    return this.checked;
  }

  set isOn(value) {
    if (this.checked === value) {
      return;
    }

    this.button.setAttribute('aria-checked', value);
    this.input.checked = value;
    this.input.dispatchEvent(new Event('change'));

    this.updateButtonWidth(value);

    if (value) {
      this.el.classList.add(`${baseClass}--on`);
    } else {
      this.el.classList.remove(`${baseClass}--on`);
    }

    this.checked = value;
  }
}

/**
 * @param {HTMLElement} element
 * @return {TextSwitch|null}
 */
export function getTextSwitchByElement(element) {
  if (textSwitches.has(element) === false) {
    return null;
  }

  return textSwitches.get(element);
}

export default function initTextSwitch() {
  const switches = document.querySelectorAll(`.${baseClass}`);
  if (switches.length <= 0) {
    return;
  }

  const createdSwitches = [...switches].map((element) => [ element, new TextSwitch(element) ]);

  for (const [ element, createdSwitch ] of createdSwitches) {
    textSwitches.set(element, createdSwitch);
  }

  document.addEventListener(LAYOUT_CHANGE_EVENT, event => {
    for (const textSwitch of textSwitches.values()) {
      textSwitch.updateButtonWidth(textSwitch.isOn);
    }
  }, { passive: true });
}
