// -------------------------------------------------------------------------------------------------
// Import
// -------------------------------------------------------------------------------------------------

import {
  forEach as _forEach,
  includes as _includes,
} from 'lodash-es';
import $ from 'jquery';
import utilLib from '../_util';
import Collapse from 'bootstrap/js/dist/collapse';

// -------------------------------------------------------------------------------------------------
// Variables
// -------------------------------------------------------------------------------------------------
const slidableBreakpoint = '767px';

// -------------------------------------------------------------------------------------------------
// Methods
// -------------------------------------------------------------------------------------------------

export function getFlexOrder($el) {
  return parseInt($el.css('order'), 10);
}

export function headerControl(commands) {
  _forEach(commands.split(' '), (command) => {
    if (_includes(['pin', 'unpin', 'freeze', 'unfreeze'], command)) {
      window.dispatchEvent(new CustomEvent(`theme_headroom_${command}`));
    }
  });
}

export function openShareWindow(url, network) {
  const specs = [
    'menubar=0',
    'status=0',
    'scrollbars=1',
  ];
  let width;
  let height;
  switch (network) {
    case 'facebook':
      width = 626;
      height = 436;
      break;
    case 'twitter':
      width = 550;
      height = 420;
      break;
    default:
      width = 640;
      height = 480;
  }
  specs.push(`width=${width}`);
  specs.push(`height=${height}`);
  window.open(url, network, specs.join(','));
}

export function shrugAlert() {
  // eslint-disable-next-line
  alert('¯\\_(ツ)_/¯');
}


export function smoothScrollTo(target, duration, spaceDesktop, spaceMobile) {
  const $target = $(target);
  const $html = $('html');
  const $body = $('body');
  const $header = $('.page-header').outerHeight(true) | 0;

  let $spaceTop = $header;

  if (spaceMobile !== undefined) {
    $spaceTop = ($header - spaceMobile);
  }

  const mediaMatcher = window.matchMedia(`(min-width: ${slidableBreakpoint})`);

  if (mediaMatcher.matches && spaceDesktop !== undefined) {
    $spaceTop = ($header - spaceDesktop);
  }

  let scrolled = false;
  if ($target.is(':visible') && !$html.is(':animated') && !$body.is(':animated')) {
    const targetPos = utilLib.getElementOffset($target[0]);
    const scrollPos = window.scrollY;
    const scrollDifferenz = (targetPos.top - scrollPos);

    if (scrollPos == targetPos.top || scrollDifferenz >= 0 && scrollDifferenz <= 100) {
      return;
    }

    headerControl('pin');
    $html.add($body).stop().animate({
      scrollTop: Math.ceil(targetPos.top - $spaceTop),
    }, duration, () => {
      setTimeout(() => {
        headerControl('unpin');
      }, 250);
    });
    scrolled = true;
  }
  return scrolled;
}

export function getPageName() {
  // Get Page name from Breadcrumbs
  const breadCrumbLastCrumb = document.querySelector('.breadcrumb-container ol > li:last-child a');
  if (breadCrumbLastCrumb != null) {
    return breadCrumbLastCrumb.textContent;
  }

  // ... if breadcrumbs fail, use page title.
  const titleMatches = document.title.match(/[^|]+/);
  if (titleMatches.length > 0) {
    return titleMatches[0].trim();
  }

  return null;
}

export function collapseBurgerMenu() {
  Collapse.getOrCreateInstance('.page-header .navbar-collapse').hide();
}

// -------------------------------------------------------------------------------------------------
// Export
// -------------------------------------------------------------------------------------------------

export default {
  getFlexOrder,
  headerControl,
  openShareWindow,
  shrugAlert,
  smoothScrollTo,
  getPageName,
};
