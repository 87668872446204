import $ from 'jquery';
import themeCommon from './_common';
import { debounce as _debounce } from 'lodash-es';
import { runOnPageload } from '../runner-pageload';

const baseClass = 'page-header-slider';
const variantClasses = {
  contrast: `${baseClass}--contrast`,
};
const slidableBreakpoint = '992px';

class ContentHeader {
  constructor(el) {
    this.el = el;
    this.swiper = null;

    this.$items = $(el).find(`.swiper-slide`);
    this.$images = $(el).find(`.${baseClass}__image img`);
    this.$contentSection = $(el).next();
    this.$swiperContainer = $(el).find('.swiper-container');
    this.$swiperPrev = $(el).find('.swiper-button-prev');
    this.$swiperNext = $(el).find('.swiper-button-next');
    this.intersectingElements = new Set();

    this.initCtas();

    this.initSlideshow().then(() => {
      this.arrowPosition();
      this.button();

      $(window).on('resize', _debounce(() => {
        this.arrowPosition();
      }, 500));

      $(this.el).addClass(`${baseClass}--loaded`);
    });

    runOnPageload(() => {
      this.startVideos();
    });
  }

  button() {
    this.mediaMatcher = window.matchMedia(`(max-width: ${slidableBreakpoint})`);
    this.buttonStyle();

    if (this.mediaMatcher.matches) {
      this.buttonStyle(true);
    }

    this.mediaMatcher.addEventListener('change', (event) => {
      if (event.matches) {
        this.buttonStyle(true);
      } else {
        this.buttonStyle();
      }
    });
  }

  startVideos() {
    for (const video of $(this.el).find(`.${baseClass}__video video`)) {
      video.play();
    }
  }

  async initSlideshow() {
    if (this.$items.length < 2) {
      return;
    }

    const {
      default: Swiper,
      Autoplay: SwiperAutoplay,
      Navigation: SwiperNavigation,
    } = await import('swiper/core');

    Swiper.use([ SwiperAutoplay, SwiperNavigation ]);

    this.swiper = new Swiper(this.$swiperContainer[0], {
      // @see https://swiperjs.com/swiper-api
      autoHeight: true,
      preloadImages: false,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      loop: true,
      navigation: {
        prevEl: this.$swiperPrev[0],
        nextEl: this.$swiperNext[0],
      },
      speed: 2000,
    });

    const observerElement = $(`.${baseClass}`);
    const contentObserver = new IntersectionObserver(elements => {
      for (const element of elements) {
        if (element.isIntersecting) {
          this.intersectingElements.add(element.target);
        } else {
          this.intersectingElements.delete(element.target);
        }
      }

      if (this.intersectingElements.size > 0) {
        this.swiper.autoplay.start();
      } else {
        this.swiper.autoplay.stop();
      }
    }, {rootMargin: "-30%"})

    for (const item of observerElement) {
      contentObserver.observe(item);
    }
  }

  arrowPosition() {
    let pageHeaderHeight = this.$images.height();

    if ($(window).width() < 992) {
      this.$swiperPrev.css('height', pageHeaderHeight + 'px');
      this.$swiperNext.css('height', pageHeaderHeight + 'px');
    } else {
      this.$swiperPrev.css('height', '');
      this.$swiperNext.css('height', '');
    }
  }

  buttonStyle(isMobile) {
    let btnClass = $(this.el).hasClass(variantClasses.contrast) ? 'btn-primary' : 'btn-outline-white';
    let ctaButtons = $(this.el).find(`.${baseClass}__cta`).find('.btn');

    if (isMobile == true) {
      btnClass = 'btn-primary';
      ctaButtons.removeClass('btn-outline-white');
    }

    ctaButtons.removeClass('btn-primary');
    ctaButtons.addClass(btnClass);
  }

  initCtas() {
    $(this.el).on('click', `button.${baseClass}__cta-btn`, (e) => {
      const btnType = e.currentTarget.dataset.type;
      if (btnType === 'scroll' && this.$contentSection.length > 0) {
        themeCommon.smoothScrollTo(this.$contentSection, 750);
      }
    });

    $(this.el).on('click', `.page-header-slider__down .fa`, () => {
      if (this.$contentSection.length > 0) {
        themeCommon.smoothScrollTo(this.$contentSection, 750);
      }
    });
  }
}

function init() {
  $(`.${baseClass}`).each((i, el) => new ContentHeader(el));
}

export default {
  init,
};
