// -------------------------------------------------------------------------------------------------
// Import
// -------------------------------------------------------------------------------------------------

import $ from 'jquery';
import { LocationsCatalog, registerCatalog } from './_locationsCatalog';

// -------------------------------------------------------------------------------------------------
// Variables
// -------------------------------------------------------------------------------------------------

const baseClass = 'slider-with-filter';

// -------------------------------------------------------------------------------------------------
// Classes
// -------------------------------------------------------------------------------------------------

class SliderWithFilter extends LocationsCatalog {
  constructor(el) {
    super(el, false);

    this.swiper = null;

    this.$items = $(el).find(`.swiper-slide`);
    this.$swiperContainer = $(el).find('.swiper-container');
    this.$swiperPrev = $(el).find('.swiper-button-prev');
    this.$swiperNext = $(el).find('.swiper-button-next');

    this.initSlideshow();

    if (this.hasSingleFilter()) {
      this.tryBuildSingularFilterView();

      this.filtersVisible = $(this.el).hasClass(`${baseClass}--hide-singular-filter`) === false;
    }
  }

  async initSlideshow() {
    if (this.$items.length < 2) {
      return;
    }

    const { default: Swiper } = await import('swiper/core');

    this.swiper = new Swiper(this.$swiperContainer[0], {
      // @see https://swiperjs.com/swiper-api

      slidesPerView: 'auto',
      navigation: {
        prevEl: this.$swiperPrev[0],
        nextEl: this.$swiperNext[0],
      },

      observeParents: true,
      observeSlideChildren: true,
      observer: true,

      speed: (1000 * (5 / 8))
    });
  }

  hasSingleFilter() {
    return this.$sectorBtns.length === 1;
  }

  tryBuildSingularFilterView() {
    this.buttonsEnabled = false;

    $(this.el).find('.locations-catalog__sector-headline').text('Aktive Auswahl');
    $(this.el).addClass('slider-with-filter--singular-filter');
  }

  updateDisplay() {
    super.updateDisplay();

    if (this.swiper != null) {
      this.swiper.update();
    }
  }
}

// -------------------------------------------------------------------------------------------------
// Methods
// -------------------------------------------------------------------------------------------------

function init() {
  $(`.${baseClass}`).filter((i, el) => {
    return $(el).children('.locations-catalog--with-slider').length === 0;
  }).each((i, el) => {
    registerCatalog(new SliderWithFilter(el));
  });
}

// -------------------------------------------------------------------------------------------------
// Export
// -------------------------------------------------------------------------------------------------

export default {
  init,
};
