export default class PlacesAutocompleteWatcher {
  constructor() {
    this.observer = new MutationObserver(this.handleMutation.bind(this));

    this.visible = false;
    this.element = null;

    this.ready = false;
    this.started = false;

    this.changeListener = null;
  }

  watchForPlacesElementCreation() {
    if (document.getElementsByClassName('pac-container').length > 0) {
      return;
    }

    const mutationObserver = new MutationObserver(((mutations, observer) => {
      let elementFound = false;

      mutationloop: for (const mutation of mutations) {
        if (mutation.addedNodes.length <= 0) {
          continue;
        }

        for (const addedNode of mutation.addedNodes) {
          if (addedNode.classList && addedNode.classList.contains('pac-container')) {
            elementFound = true;

            break mutationloop;
          }
        }
      }

      if (elementFound) {
        this.ready = true;

        if (this.started) {
          this.startObserve();
        }

        observer.disconnect();
      }
    }));

    mutationObserver.observe(document.getElementsByTagName('body')[0], {
      childList: true,
      subtree: true,
    });
  }

  start() {
    this.started = true;

    if (this.ready === false) {
      [ this.element ] = document.getElementsByClassName('pac-container');

      this.ready = this.element != null;
      if (this.element == null) {
        this.watchForPlacesElementCreation();

        return;
      }
    }

    this.startObserve();
  }

  startObserve() {
    [ this.element ] = document.getElementsByClassName('pac-container');
    this.observer.observe(this.element, {
      attributeFilter: [ 'style' ],
    });
  }

  stop() {
    this.started = false;

    this.changeListener?.(false);
    this.visible = false;

    this.observer.disconnect();
    this.element = null;
  }

  handleMutation() {
    const oldVisible = this.visible;
    this.visible = (this.element.style.display === 'none') === false;

    if (this.visible === oldVisible) {
      return;
    }

    this.changeListener?.(this.visible);
  }

  /**
   * @callback {(boolean) => void} callback
   */
  addChangedListener(callback) {
    this.changeListener = callback;
  }
}
