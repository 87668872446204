export default function runOnVisible(element, callback) {
  const observer = new IntersectionObserver(([ entry ]) => {
    if (entry.isIntersecting === false) {
      return;
    }

    try {
      callback();
    } finally {
      observer.unobserve(element);
      observer.disconnect();
    }
  });

  observer.observe(element);
}
