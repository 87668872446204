// -------------------------------------------------------------------------------------------------
// Import
// -------------------------------------------------------------------------------------------------

import $ from 'jquery';

// -------------------------------------------------------------------------------------------------
// Variables
// -------------------------------------------------------------------------------------------------

const baseClass = 'jumbo';

// -------------------------------------------------------------------------------------------------
// Classes
// -------------------------------------------------------------------------------------------------

class Jumbo {
  constructor(el) {
    this.waypoint = null;
    this.previousY = 0;
    this.previousRatio = 0;

    this.el = el;
    this.$vHeight = $(el).find(`.${baseClass}__v-height`);
    this.$imageMedia = $(el).find(`picture.${baseClass}__media img`);

    if (this.$imageMedia.length >= 1) {
      this.initWaypoint();
    }
  }

  initWaypoint() {
    this.waypoint = new IntersectionObserver((o) => {
      const entry = o[0];
      const currentY = entry.boundingClientRect.y;
      const currentRatio = entry.intersectionRatio;

      let bgPos = (o[0].intersectionRatio * 50);

      if (currentY < this.previousY) {
        if (currentRatio > this.previousRatio) {
          // down enter
        } else {
          // down leave
          bgPos = (100 - bgPos);
        }
      } else if (currentY > this.previousY) {
        if (currentRatio < this.previousRatio) {
          // up leave
        } else {
          // up enter
          bgPos = entry.isIntersecting ? (100 - bgPos) : 0;
        }
      }

      this.$imageMedia.css({
        objectPosition: `0% ${bgPos}%`,
      });

      this.previousY = currentY;
      this.previousRatio = currentRatio;
    }, {
      rootMargin: '0px 0px',
      threshold: [...Array(50).keys()].map((x) => (x / 50)),
    });
    this.waypoint.observe(this.$vHeight[0]);
  }
}

// -------------------------------------------------------------------------------------------------
// Methods
// -------------------------------------------------------------------------------------------------

function init() {
  $(`.${baseClass}`).each((i, el) => new Jumbo(el));
}

// -------------------------------------------------------------------------------------------------
// Export
// -------------------------------------------------------------------------------------------------

export default {
  init,
};
