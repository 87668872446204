// -------------------------------------------------------------------------------------------------
// Import
// -------------------------------------------------------------------------------------------------

import {
  intersection as _intersection,
  debounce as _debounce,
  merge as _merge,
} from 'lodash-es';
import $ from 'jquery';
import utilLib from '../_util';
import { FilterableMap } from './_filterableMap';
import { pushTrackerEvent } from '../_googleTagManager';
import { getTextSwitchByElement } from './_textSwitch';
import { runOnPageload } from '../runner-pageload';

// -------------------------------------------------------------------------------------------------
// Variables
// -------------------------------------------------------------------------------------------------

const baseClass = 'locations-catalog';
let catalogs = [];
let globalFilter = {};

// -------------------------------------------------------------------------------------------------
// Classes
// -------------------------------------------------------------------------------------------------

export class LocationsCatalog {
  constructor(el, useFadeOutAnimation) {
    this._filter = {};
    this._useFadeOutAnimation = useFadeOutAnimation;
    this._buttonsEnabled = true;
    this._filtersVisible = true;
    this._mapVisible = false;

    this.el = el;
    this.$sectorBtns = $(el).find(`.${baseClass}__sector-btn`);
    this.$sectorReset = $(el).find(`.${baseClass}__sector-reset`);
    this.$displayCountComplete = $(el).find(`.${baseClass}__display-count__complete`);
    this.$displayCountN = $(el).find(`.${baseClass}__display-count__n`);
    this.$items = $(el).find(`.${baseClass}__item`);
    this.$mapWrapper = $(el).find(`.${baseClass}__map-wrapper`);
    this.$mapSearch = $(el).find(`.${baseClass}__map-search`);
    this.$map = this.$mapWrapper.find(`.${baseClass}__map`);

    this._filterableMap = new FilterableMap(this.$map, this.el.dataset.marker, this.el.dataset.markerLocation);
    this._filterableMap.addSuggestionVisibleListener(this.onSuggestionsVisible.bind(this));

    this.$displayCountComplete.text(this.$items.length);

    if (useFadeOutAnimation) {
      const fadeOutDuration = window.getComputedStyle(this.$items.parent()[0]).transitionDuration;
      this._debouncedAnimationFinish = _debounce(this.onAnimationFinished.bind(this), utilLib.convertCssDurationToMilliseconds(fadeOutDuration) * 1.25);
    } else {
      this._debouncedAnimationFinish = this.onAnimationFinished;
    }

    $(this.el).find('[name=map-toggle]').change((e) => {
      if (e.currentTarget.checked) {
        this.showMap();
      } else {
        this.hideMap();
      }
    });

    this.$sectorBtns.on('click', (e) => {
      if (this.buttonsEnabled === false) {
        return;
      }

      const btnEl = e.currentTarget;

      if (btnEl.classList.contains('active')) {
        this.removeFilter(btnEl.dataset.type, btnEl.dataset.id);

        return;
      }

      this.addFilter(btnEl.dataset.type, btnEl.dataset.id);
    }).prop('disabled', false);

    this.$sectorReset.on('click', 'button', () => {
      this.clearFilters();
    });

    this.updateItems(false);
    this.updateSectorButtons();

    const sector = utilLib.getQueryVar('sector');
    if (sector != null) {
      this.addFilter(1, sector);
    }

    const type = utilLib.getQueryVar('type');
    if (type != null) {
      this.addFilter(2, type);
    }


    runOnPageload(() => {
      const mapVisible = this.el.classList.contains(`${baseClass}--map-visible`);
      this.mapSwitch = getTextSwitchByElement(this.el.querySelector(`.${baseClass}__boxes-header-switch .text-switch`));

      if (mapVisible) {
        if (this.mapSwitch != null) {
          this.mapSwitch.isOn = true;
        } else {
          this.showMap();
        }
      }
    });
  }

  hasFilterSector() {
    return Object.keys(this._filter).length >= 1;
  }

  updateSectorButtons() {
    this.$sectorBtns.removeClass('active');

    for (const [type, ids] of Object.entries(this._filter)) {
      for (const id of ids) {
        $(this.el).find(`[data-type="${type}"][data-id=${id}]`).addClass('active');
      }
    }

    this.$sectorReset.toggleClass('d-none', !this.hasFilterSector());
  }

  onAnimationFinished() {
    const visibleItems = this.$items
         .addClass('d-none')
         .filter(((i, el) => {
            let matchSector = true;
            const itemFilters = JSON.parse(el.dataset.filter);

            for (const [ type, values ] of Object.entries(_merge(this._filter, globalFilter))) {
              matchSector = matchSector
                && itemFilters[type] != null
                && _intersection(itemFilters[type], values).length > 0;

              if (matchSector === false) {
                break;
              }
            }

            return matchSector;
         }));

    visibleItems.removeClass('d-none');

    this.$items.parent().add(this.$mapWrapper).removeClass('locations-catalog__fade-out');

    let count = visibleItems.length;
    if (Number.isNaN(count)) count = 0;

    this.$displayCountN.text(new Intl.NumberFormat('de-DE').format(count));

    this.showMarkers(this.convertItemsToMarkerData(visibleItems));
  }

  updateItems(overwriteAnimation = null) {
    this.$items.parent().add(this.$mapWrapper).addClass('locations-catalog__fade-out');

    if (overwriteAnimation == null) {
      this._debouncedAnimationFinish();
    } else {
      this.onAnimationFinished();
    }
  }

  addFilter(type, value) {
    if (this._filter[type] == null) {
      this._filter[type] = [];
    }

    this._filter[type].push(parseInt(value));
    this._filter[type] = [ ...new Set(this._filter[type]) ];

    this.updateDisplay();
  }

  removeFilter(type, value) {
    if (this._filter[type] == null) {
      return;
    }

    const foundIndex = this._filter[type].indexOf(parseInt(value));
    if (foundIndex === -1) {
      return;
    }

    this._filter[type].splice(foundIndex, 1);

    if (this._filter[type].length === 0) {
      delete this._filter[type];
    }

    this.updateDisplay();
  }

  clearFilters() {
    this._filter = {};

    this.updateDisplay();
  }

  updateDisplay() {
    this.updateSectorButtons();
    this.updateItems();
  }

  async showMap() {
    $(this.el).addClass(`${baseClass}--map-visible`);

    await this._filterableMap.showMap();
    this._filterableMap.enableAutocomplete($(this.el).find('[name=places-search]')[0]);
    this._mapVisible = true;

    this.updateItems(true);

    pushTrackerEvent('showLocationsMap');
  }

  hideMap() {
    $(this.el).removeClass(`${baseClass}--map-visible`);

    this._filterableMap.hideMap();

    this._mapVisible = false;

    pushTrackerEvent('hideLocationsMap');
  }

  convertItemsToMarkerData(items) {
    return Array.from(items).map(item => {
      const lat = parseFloat(item.dataset.lat);
      const lng = parseFloat(item.dataset.lng);

      const title = item.querySelector('.leaf__body h3').textContent.trim();
      const link = item.querySelector('.leaf__footer a').href;

      const infoWindowContent = `<div class="location-map__info-window-content">
            <div class="location-map__info-window-title">
                <b>${title}</b>
            </div>
            <div class="d-flex justify-content-center">
                <a href="${link}" class="btn btn-primary">
                  <div class="row gx-3 align-items-center">
                    <div class="col">
                      Zum Standort
                    </div>
                    <div class="col-auto">
                      <i class="fa fa-chevron-right" aria-hidden="true"></i>
                    </div>
                  </div>
                </a>
            </div>
            <div class="location-map__info-window-plain-link">
                <a href="${this.buildDirectionsUrl(lat, lng)}">Routenplaner</a>
            </div>
          </div>`;

      return {
        lat,
        lng,
        infoWindow: infoWindowContent,
        title,
      };
    });
  }

  buildDirectionsUrl(lat, lng) {
    const url = new URL('maps/dir/', 'https://www.google.com/');

    url.searchParams.set('api', 1);
    url.searchParams.set('destination', `${lat},${lng}`);

    return url.href;
  }

  onSuggestionsVisible(visible) {
    if (visible) {
      this.$mapSearch.addClass(`${baseClass}__map-search--places-visible`);
    } else {
      this.$mapSearch.removeClass(`${baseClass}__map-search--places-visible`);
    }
  }

  showMarkers(markers) {
    if (this._mapVisible === false) {
      return;
    }

    this._filterableMap.clearMarkers();
    for (let { lat, lng, infoWindow, title } of markers) {
      this._filterableMap.addMarker(lat, lng, infoWindow, title);
    }

    this._filterableMap.focusOnMarkers();
  }

  set buttonsEnabled(value) {
    this._buttonsEnabled = value;
  }

  get buttonsEnabled() {
    return this._buttonsEnabled;
  }

  set filtersVisible(value) {
    if (this._filtersVisible === value) {
      return;
    }

    this._filtersVisible = value;

    if (value) {
      $(this.el).find(`.${baseClass}__sector`).show();
    } else {
      $(this.el).find(`.${baseClass}__sector`).hide();
    }
  }

  get filtersVisible() {
    return this._filtersVisible;
  }
}

// -------------------------------------------------------------------------------------------------
// Methods
// -------------------------------------------------------------------------------------------------

export function init() {
  $(`.${baseClass}`).not(`.${baseClass}--with-slider`).each((i, el) => {
    registerCatalog(new LocationsCatalog(el, $(el).hasClass(`${baseClass}--no-animations`) === false));
  });
}

export function registerCatalog(catalog) {
  catalogs.push(catalog);
}

export function addGlobalFilter(type, value) {
  if (globalFilter[type] == null) {
    globalFilter[type] = [];
  }

  globalFilter[type].push(parseInt(value));
  globalFilter[type] = [ ...new Set(globalFilter[type]) ];

  updateAllDisplays();
}

export function removeGlobalFilter(type, value) {
  if (globalFilter[type] == null) {
    return;
  }

  const foundIndex = globalFilter[type].indexOf(parseInt(value));
  if (foundIndex === -1) {
    return;
  }

  globalFilter[type].splice(foundIndex, 1);

  if (globalFilter[type].length === 0) {
    delete globalFilter[type];
  }

  updateAllDisplays();
}

export function resetGlobalFilter() {
  globalFilter = {};

  updateAllDisplays();
}

function updateAllDisplays() {
  for (const catalog of catalogs) {
    catalog.updateDisplay();
  }
}

// -------------------------------------------------------------------------------------------------
// Export
// -------------------------------------------------------------------------------------------------

export default {
  init,
  addGlobalFilter,
  removeGlobalFilter,
};
