import $ from 'jquery';
import utilLib from '../_util';
const baseClass = 'slider-carousel';

class ContentHeader {
  constructor(el) {
    this.el = el;
    this.swiper = null;

    this.$items = $(el).find(`.swiper-slide`);
    this.$ctas = $(el).find(`.${baseClass}__cta`);
    this.$images = $(el).find(`.${baseClass}__image img`);
    this.$swiperContainer = $(el).find('.swiper-container');
    this.$swiperPrev = $(el).find('.swiper-button-prev');
    this.$swiperNext = $(el).find('.swiper-button-next');

    this.loadImages();
    this.initSlideshow();
  }

  async initSlideshow() {
    if (this.$items.length < 2) {
      return;
    }

    const {
      default: Swiper,
      Autoplay: SwiperAutoplay,
      Navigation: SwiperNavigation,
    } = await import('swiper/core');

    Swiper.use([SwiperAutoplay, SwiperNavigation]);

    this.swiper = new Swiper(this.$swiperContainer[0], {
      // @see https://swiperjs.com/swiper-api
      slidesPerView:'auto',
      loopedSlides: 2,

      observeParents: true,
      observeSlideChildren: true,
      observer: true,

      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      loop: true,
      navigation: {
        prevEl: this.$swiperPrev[0],
        nextEl: this.$swiperNext[0],
      },
      speed: (1000 * (5 / 8)),
    });
  }

  loadImages() {
    this.$images.each((i, e) => {
      const src = e.getAttribute('src');
      if (src) {
        utilLib.loadImageThen(src, () => {
          if (i === 0) $(this.el).addClass(`${baseClass}--loaded`);
        });
      }
    });
  }
}

function init() {
  $(`.${baseClass}`).each((i, el) => new ContentHeader(el));
}

export default {
  init,
};
